import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Algemene = () => {
  return (
    <Layout>
      <Seo title="Algemene Voorwaarden" />
      <section className="pt-60px px-30px mt-70px">
        <div className="max-w-700px mx-auto">
          <h1 className="text-primary text-3xl leading-1.2 mb-10px">
            Algemene voorwaarden
          </h1>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            1. Eerstmediation.nl is een eenmanszaak die gedreven wordt voor
            rekening en risico van Rianne Hillenaar-Cöp. Eerstmediation.nl is
            een Mediation- en Advocatenkantoor gevestigd in de gemeente Land van
            Cuijk (Kamer van Koophandel 64700976). Eerstmediation.nl kan geen
            derdengelden ontvangen.
          </p>
          <strong className="text-primary text-lg leading-1.2 mb-30px block">
            De opdracht
          </strong>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            2. Deze Algemene Voorwaarden zijn van toepassing op iedere opdracht
            aan Eerstmediation.nl, daaronder begrepen iedere vervolgopdracht of
            gewijzigde opdracht. Toepasselijkheid van de algemene voorwaarden
            van de opdrachtgever wordt uitgesloten.
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            3. Eerstmediation.nl heeft het recht om de opdracht te beëindigen in
            geval van: a) niet betaling van facturen; b) verzuim van
            opdrachtgever om informatie te verstrekken en/of het verstrekken van
            onjuiste informatie en/of onvolledige informatie; c) het ontbreken
            van het benodigde vertrouwen tussen opdrachtgever en
            Eerstmediation.nl; d) een belangenconflict met een andere cliënt van
            Eerstmediation.nl.
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            4. Bij uitval en arbeidsongeschiktheid van mr M.A.T. Hillenaar-Cöp
            zal mr I.M.H. Bloemen (advocaat-mediator ten kantore van Wools
            Bloemen Van Schaik Advocaten te Nijmegen) als waarnemer optreden.
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            5. Niet alleen Eerstmediation.nl, maar al degenen die voor
            Eerstmediation.nl werkzaam zijn of waren alsmede in het kader van de
            opdracht door Eerstmediation.nl ingeschakelde derden kunnen op deze
            Algemene Voorwaarden, een beroep doen.
          </p>

          <strong className="text-primary text-lg leading-1.2 mb-30px block">
            Persoonsgegevens
          </strong>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            6. Opdrachtgever verleent toestemming voor het verwerken en bewerken
            van zijn persoonsgegevens. De verwerking geschiedt in het kader van
            de uitvoering van de opdracht, het voldoen aan wettelijke
            verplichtingen, relatiebeheer en marketing. De wijze waarop
            Eerstmediation.nl omgaat met persoongegevens is vastgelegd in het
            privacystatement dat via de website te raadplegen is.
          </p>

          <strong className="text-primary text-lg leading-1.2 mb-30px block">
            Kosten
          </strong>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            7. Voor de uitvoering van de door Eerstmediation.nl voor
            opdrachtgever verrichte werkzaamheden wordt een honorarium en
            omzetbelasting, in rekening gebracht. Kosten van (in overleg)
            ingeschakelde deskundigen, griffierechten en andere verschotten
            worden aan opdrachtgever doorbelast. Eerstmediation.nl is steeds
            gerechtigd bij de aanvaarding en tijdens de uitvoering van de
            opdracht betaling van een voorschot te verlangen.
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            8. Eerstmediation.nl zal maandelijks een factuur zenden. De factuur
            dient te worden voldaan binnen 14 dagen na ontvangst.
            Eerstmediation.nl is gerechtigd een rente van 1% per maand in
            rekening te brengen over het bedrag dat niet binnen die termijn is
            betaald.
          </p>

          <strong className="text-primary text-lg leading-1.2 mb-30px block">
            Klachten
          </strong>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            9. Bij klachten over de dienstverlening of handelwijze van bij
            Eerstmediation.nl werkzame personen kan opdrachtgever gebruik maken
            van de Kantoorklachtenregeling Advocatuur en/of de Klachtenregeling
            van de MfN. Beide regelingen zijn via de website van
            Eerstmediation.nl te raadplegen.
          </p>

          <strong className="text-primary text-lg leading-1.2 mb-30px block">
            Aansprakelijkheid
          </strong>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            10. Eerstmediation.nl is verzekerd voor beroepsaansprakelijkheid.
            Iedere aansprakelijkheid van Eerstmediation.nl is beperkt tot het
            bedrag dat in het desbetreffende geval onder de
            beroepsaansprakelijkheidsverzekering van Eerstmediation.nl wordt
            uitgekeerd, vermeerderd met het eigen risico zoals opgenomen in deze
            verzekeringspolis. Indien en voor zover om welke reden dan ook, geen
            uitkering plaatsvindt uit hoofde van voornoemde verzekering, is de
            aansprakelijkheid van Eerstmediation.nl beperkt tot het door
            Eerstmediation.nl in verband met de betreffende opdracht in rekening
            gebrachte honorarium, met een maximum van 10.000,- EURO.
          </p>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            11. Alle vorderingsrechten jegens Eerstmediation.nl verband houdende
            met de door Eerstmediation.nl verrichte werkzaamheden vervallen in
            ieder geval één jaar na het moment waarop de betrokkene bekend werd
            of redelijkerwijs bekend kon zijn met het bestaan van deze rechten.
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            12. Indien communicatie tussen opdrachtgever en Eerstmediation.nl
            plaatsvindt door elektronische middelen dan is Eerstmediation.nl
            jegens opdrachtgever noch derden aansprakelijk voor schade die het
            gevolg is van de verzending van virussen en/of onregelmatigheden in
            de elektronische communicatie, en voor niet ontvangen berichten,
            behoudens in geval van opzet of grove schuld.
          </p>

          <strong className="text-primary text-lg leading-1.2 mb-30px block">
            Slotbepalingen
          </strong>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            13. Indien enige bepaling in deze Algemene Voorwaarden geheel of
            gedeeltelijk onverbindend wordt verklaard, dan zal de onverbindende
            bepaling op zodanige wijze worden uitgelegd dat zij rechtsgeldig is
            en overeenkomt met haar strekking.
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            14. Op de rechtsverhouding tussen Eerstmediation.nl en
            opdrachtgever, alsmede degenen die van haar diensten gebruik maken,
            is Nederlands recht van toepassing. Nu Eerstmediation.nl gevestigd
            is in de gemeente Land van Cuijk zullen geschillen uitsluitend
            worden beslecht door de bevoegde rechter te ‘s-Hertogenbosch.
          </p>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            Land van Cuijk, 1 januari 2022
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Algemene
